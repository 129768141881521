<template>
  <div>
    <a-table
      :columns="column"
      :data-source="dataTable"
      :pagination="{ pageSize: 30, hideOnSinglePage: true }"
      bordered
      size="large"
    >
    </a-table>
  </div>
</template>

<script>
import { jenisBeasiswaList } from '@/helpers/listsPpdbSelect'
const column = [
  {
    dataIndex: 'jenis',
    key: 'jenis',
    title: 'Jenis',
    scopedSlots: { customRender: 'jenis' },
    align: 'left',
  },
  {
    dataIndex: 'keterangan',
    key: 'keterangan',
    title: 'Keterangan',
    scopedSlots: { customRender: 'keterangan' },
    align: 'left',
  },
  {
    dataIndex: 'tahun_mulai',
    key: 'tahun_mulai',
    title: 'Tahun Mulai',
    scopedSlots: { customRender: 'tahun_mulai' },
    align: 'left',
  },
  {
    dataIndex: 'tahun_selesai',
    key: 'tahun_selesai',
    title: 'Tahun Selesai',
    scopedSlots: { customRender: 'tahun_selesai' },
    align: 'left',
  },
]

export default {
  props: {
    datas: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      column,
      jenisBeasiswaList,
      dataTable: [],
    }
  },
  computed: {
  },
  methods: {
  },
  created() {
    this.dataTable = this.datas.beasiswas.map(data => {
      return {
        ...data,
        key: data.id,
      }
    })
  },
}
</script>

<style>
</style>
